import { Box, Grid, Typography } from "@mui/material";
import ConcertTicketImg from "../../../assets/images/tickets/concert.jpg";
import SportTicketImg from "../../../assets/images/tickets/sport.jpg";
import FlightTicketImg from "../../../assets/images/tickets/flight-ticket.png";
import TrainTicketImg from "../../../assets/images/tickets/train.jpg";
import LondonBusTourTicketImg from "../../../assets/images/tickets/london-bus.jpg";
import LondonAttractionsTicketImg from "../../../assets/images/tickets/london-attractions.jpg";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
export default function TicketsSingleItems() {
  const { id } = useParams();

  useEffect(() => {
    // Script yükleme
    const script = document.createElement("script");
    script.src = "https://whitelabel-widget.musement.com/bootstrap.js";
    script.async = true;

    // Script'i DOM'a ekle
    document.body.appendChild(script);

    // Temizleme işlemi
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  console.log(id, "id her");
  const ticketItems = [
    {
      id: 1,
      title: "Harry Potter Warner Bros. Tour from London",
      url: "/frame/https%3A%2F%2Fwidgets.prioticket.com%2FjLTZZBZ8%2FproductDetail%2F24858",
      imgUrl:
        "https://teststudent.londonist.co.uk/static/media/harry_potter_warner.caf08be6aa6703fa2228.jpg",
    },
    {
      id: 2,
      title: "London Eye, Madame Tussauds & Sea Life London Tickets",
      url: "/frame/https%3A%2F%2Fwidgets.prioticket.com%2FjLTZZBZ8%2FproductDetail%2F14411",
      imgUrl:
        "https://teststudent.londonist.co.uk/static/media/london_eye.8f2f49c37d567d168e39.jpg",
    },
    {
      id: 3,
      title: "Westminster Abbey",
      url: "/frame/https%3A%2F%2Fwidgets.prioticket.com%2FjLTZZBZ8%2FproductDetail%2F14162",
      imgUrl:
        "https://teststudent.londonist.co.uk/static/media/westminster_abbey.d9fb3f1e3cf5059f9cc2.jpg",
    },
    {
      id: 4,
      title: "View from the Shard Essential Tickets",
      url: "/frame/https%3A%2F%2Fwidgets.prioticket.com%2FjLTZZBZ8%2FproductDetail%2F14294",
      imgUrl:
        "https://teststudent.londonist.co.uk/static/media/the_view_from_the_shard.9db2ad1729d5660afebd.jpg",
    },
    {
      id: 5,
      title: "Stonehenge and Bath with Bridgerton Walking tour",
      url: "/frame/https%3A%2F%2Fwidgets.prioticket.com%2FjLTZZBZ8%2FproductDetail%2F13904",
      imgUrl:
        "https://teststudent.londonist.co.uk/static/media/stonehenge_and_bath_with_bridgerton.4d8737188faf247d4075.jpg",
    },
  ];
  return (
    <Grid container spacing={3}>
      {id === "1" ? (
        <>
          {ticketItems?.map((item, idx) => {
            return (
              <Grid item xs={12} md={4} key={idx}>
                <Link
                  to={item.url ? item.url : "javascript:;"}
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    display: "flex",
                    boxShadow: "0 0 5px #efefef",
                    borderRadius: "1rem",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      transform: "scale(1)",
                      transition: "transform .35s",
                      "&:hover": {
                        transform: "scale(1.05)",
                        transition: "transform .35s",
                      },
                      overflow: "hidden",
                      position: "relative",

                      padding: "1rem",
                      height: "300px",
                      backgroundImage: `url(${item.imgUrl})`,
                      width: "100%",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "top",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      background: "#0000004f",
                      width: "100%",
                      height: "50px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      padding: "1rem",
                    }}
                  >
                    <Typography sx={{ color: "#ffffff" }}>
                      {item.title}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            );
          })}
        </>
      ) : id === "2" ? (
        <Grid item xs={12}>
          <div
            data-whitelabel-domain="www.musement.com"
            data-coords-latitude="51.509865"
            data-coords-longitude="-0.118092"
            data-number-of-elements="15"
            data-pass-through-params='{"aid":"londonist-dmc-8646"}'
          ></div>
        </Grid>
      ) : null}
    </Grid>
  );
}
